import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Header from './Header';
import Footer from './Footer';
import CardComponent from './CardComponent';
import axios from 'axios';

const App = () => {
    const [cards, setCards] = useState([]);
    const [telegram, setTelegram] = useState(null);
    const [facebook, setFacebook] = useState(null);
    const [twitter, setTwitter] = useState(null);
    const [instagram, setInstagram] = useState(null);
    const [title, setTitle] = useState(null);
    const [logoText, setLogoText] = useState(null);

    const fetchConfig = async () => {
        try {
          const response = await axios.get('config.json');
          setTelegram(response.data[0].Telegram);
          setFacebook(response.data[0].Facebook);
          setTwitter(response.data[0].Twitter);
          setInstagram(response.data[0].Instagram);
          setTitle(response.data[0].Title);
          setLogoText(response.data[0].LogoText);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

    useEffect(() => {
        fetch('/products.json')
            .then((response) => response.json())
            .then((data) => setCards(data))
            .catch((error) => console.error('Error fetching card data:', error));

        fetchConfig();
    }, []);

    const renderCardRows = () => {
        const rows = [];
        for (let i = 0; i < cards.length; i += 3) {
            rows.push(
                <div className="row" key={i}>
                    {cards.slice(i, i + 3).map((card, index) => (
                        <CardComponent key={index} {...card} />
                    ))}
                </div>
            );
        }
        return rows;
    };

    return (
        <div className="App">
            <Header logoText={logoText}/>
            <div className="container mt-4">
                <h1 className="big-title text-center">{title}</h1>
            </div>
            <div className="container mt-4">
                {renderCardRows()}
            </div>
            <Footer telegram={telegram} facebook={facebook} twitter={twitter} instagram={instagram}/>
        </div>
    );
};

export default App;
